import "./Team.css";

export default function Team() {
  return (
    <>
      <section className="teamsection" id="brochure-page2">
        <div className="teamdiv">
          <h1 className="teamheading">Who are we?</h1>

          <p>
            At Techmentorcore, we are more than just an Edtech platform; we are
            a dynamic team of young visionaries dedicated to transforming the
            teaching landscape in India. Our mission is to bridge the gap
            between skills and employability in the rapidly evolving technology
            market.
          </p>

          <p>
            We provide cutting-edge tech skills training, preparing students
            thoroughly for job interviews. Our programs include
            industry-standard projects, ensuring our students gain hands-on
            experience and are ready to excel in their careers from day one.
          </p>
        </div>
      </section>
    </>
  );
}

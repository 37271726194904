import liveclasses from "../Assets/Images/live classes.png";
import doubtsolving from "../Assets/Images/doubt-solving.png";
import expertguidance from "../Assets/Images/expert-guidance.png";
import projects from "../Assets/Images/50+projects.png";
import access from "../Assets/Images/lifetimecontentaccess.png";
import weeklycontest from "../Assets/Images/weekly-contest.png";
import coding from "../Assets/Images/coding.png";
import dedicatedcoach from "../Assets/Images/dedicated career coach.png";
import hrmock from "../Assets/Images/hr mock.png";
import resume from "../Assets/Images/resume.png";
import companyspecific from "../Assets/Images/companyspecific.png";

import student1 from "../Assets/Images/students/ashish dash.jpeg";
import student2 from "../Assets/Images/students/debasish sundaray.jpeg";
import student3 from "../Assets/Images/students/satyabrata dhal.jpeg";
import student4 from "../Assets/Images/students/chandan.jpeg";
import saurav from "../Assets/Images/students/saurav sable.JPG";
import sarthak from "../Assets/Images/students/sarthak singh.png";
import priya from "../Assets/Images/students/priya.webp";
import prakhar from '../Assets/Images/students/prakhar.webp';
import shubham from '../Assets/Images/students/shubham.webp';

import tcs from "../Assets/Images/company-logo/tcs.png";
import kovaion from "../Assets/Images/company-logo/kovaion.png";
import wipro from "../Assets/Images/company-logo/wipro.png";
import indexnine from "../Assets/Images/company-logo/indexnine.png";
import airtel from "../Assets/Images/company-logo/airtel.png";
import hcl from "../Assets/Images/company-logo/hcl.png";
import amazon from "../Assets/Images/company-logo/amazon.png";
import makemytrip from '../Assets/Images/company-logo/make-my-trip.png';
import capgemini from '../Assets/Images/company-logo/capgemini.png';

import highlight1 from "../Assets/Images/highlight1.png";
import highlight2 from "../Assets/Images/highlight2.png";
import highlight3 from "../Assets/Images/highlight3.png";
import highlight4 from "../Assets/Images/highlight4.png";
import highlight5 from "../Assets/Images/highlight5.png";
import highlight6 from "../Assets/Images/highlight6.png";

import mockinterview from "../Assets/Images/highlights/mock interview.png";
import questions from "../Assets/Images/highlights/questions.png";
import placementmock from "../Assets/Images/highlights/placement mock.png";
import mentorshipsessions from "../Assets/Images/highlights/mentorship.png";
import pause from "../Assets/Images/highlights/coursepause.png";

// import eligible1 from '../Assets/Images/eligible1.png';
// import eligible2 from '../Assets/Images/eligible2.png';
// import eligible3 from '../Assets/Images/eligible3.png';

export const featureData = [
  {
    featureimg: liveclasses,
    featurename: "Live Classes",
  },
  {
    featureimg: expertguidance,
    featurename: "1 on 1 Expert Guidance",
  },
  {
    featureimg: projects,
    featurename: "50+ Projects",
  },
  {
    featureimg: access,
    featurename: "Lifetime access to Content",
  },
  {
    featureimg: doubtsolving,
    featurename: "Doubt Solving",
  },
  {
    featureimg: weeklycontest,
    featurename: "Weekly Contest",
  },
  {
    featureimg: coding,
    featurename: "5000+ Coding Questions",
  },
  {
    featureimg: dedicatedcoach,
    featurename: "Dedicated Career Coach",
  },
  {
    featureimg: hrmock,
    featurename: "Mock HR Interviews",
  },
  {
    featureimg: resume,
    featurename: "Resume & Portfolio Building",
  },
  {
    featureimg: companyspecific,
    featurename: "Company Specific Preparation",
  },
];

export const Highlight = [
  {
    imgurl: highlight1,
    title:
      "Expert-Led Live Lectures from Top Tech Professionals on Weekdays"
  },
  {
    imgurl: highlight2,
    title:
      "On-Demand Lecture Recordings for Easy Review and Catch-Up",
  },
  {
    imgurl: highlight3,
    title: "Daily Practice Assignments to Reinforce Key Concepts",
  },
  {
    imgurl: highlight4,
    title:
      "Personalized Learning Support with Live Doubt Resolution and Mentorship",
  },
  {
    imgurl: highlight5,
    title:
      "Comprehensive Skill Evaluation with Weekend Contests, Module-End Tests, and Mock Interviews",
  },
  {
    imgurl: highlight6,
    title:
      "Extensive Self-Learning Resources: Notes, Coding Challenges, Major Projects, and Soft Skill Training",
  },
];

export const HighlightBottom = [
  {
    image: mockinterview,
    desc: "5 Mid & Full Course Mock Interviews",
  },
  {
    image: questions,
    desc: "3000+ Coding Questions",
  },
  {
    image: placementmock,
    desc: "3 Placement Mocks",
  },
  {
    image: mentorshipsessions,
    desc: "5 Mentorship Sessions",
  },
  {
    image: pause,
    desc: "Pause the Course for Upto 4 Months",
  },
];

export const CurriculumData = [
  {
    title: "Introduction to Programming",
    desc: "Begin your journey as a software developer with fundamental programming concepts.",
  },
  {
    title: "Fundamental Data Structures and Algorithms",
    desc: "Explore essential topics in data structures and algorithms.",
  },
  {
    title: "Advanced Data Structures and Algorithms",
    desc: "Deepen your understanding and problem-solving skills in DSA.",
  },
  {
    title: "Advanced Algorithms and Problem Solving",
    desc: "Analyze complex algorithms and optimize solutions for challenging problems.",
  },
  {
    title: "Spring and Spring Boot Basics",
    desc: "Learn the fundamentals of backend development and API creation with Spring.",
  },
  {
    title: "Advanced Spring Development",
    desc: "Master Spring framework through hands-on projects and implementations.",
  },
  {
    title: "HTML, CSS, and JavaScript Fundamentals",
    desc: "Gain proficiency in client-side programming and frontend technologies.",
  },
  {
    title: "Full-Stack Web Development Projects",
    desc: "Build full-stack web applications integrating frontend and backend technologies.",
  },
  {
    title: "Advanced Data Structures and Algorithms II",
    desc: "Explore dynamic programming, backtracking, and revise key concepts.",
  },
];


export const PricingData = [
  {
    title: "Pay After Placement",
    desc: "Limited seats for top performers in the selection process with 0 upfront cost!",
    installments: [
      {
        package1: "3-5 LPA",
        installment1: "₹ 1.2 lakh",
      },
      {
        package1: "5-10 LPA",
        installment1: "₹ 3 lakh",
      },
      {
        package1: "10-15 LPA",
        installment1: "₹ 30% of CTC",
      },
      {
        package1: "20+ LPA",
        installment1: "₹ 30% of CTC",
      },
    ],
    notice:
      "Placement services will have to start immediately after course completion",
  },
  // {
  //   title: "Pay Tuition Fee",
  //   desc: "Pay a minimal Tuition fees in monthly instalments and pay remaining fee after placement.",
  //   price: "₹ 6250/month",
  //   pricedesc: "In up to 12 month, Total ₹ 75000",
  //   payrest: "Pay rest after your Placement in upto 12 months installments",
  //   installments: [
  //     {
  //       package1: "3-5 LPA",
  //       installment1: "40,000",
  //     },
  //     {
  //       package1: "5-10 LPA",
  //       installment1: "₹ 60,000",
  //     },
  //     {
  //       package1: "10-15 LPA",
  //       installment1: "₹ 1,00,000",
  //     },
  //     {
  //       package1: "20+ LPA",
  //       installment1: "₹ 1,50,000",
  //     },
  //   ],
  //   notice:
  //     "Placement Services can be availed anytime up to 3 years from Course Completion.",
  // },
];

export const StudentDetailsData = [
  {
    heading: "Student with career gap",
    name: "Ashish Dash",
    studentimg: student1,
    role: "System Engineer",
    companyimg: tcs,
    quote: "“From losing hope to now working as an System Engineer at TCS”",
  },
  {
    heading: "Non-IT Working Professional",
    name: "Debashish Sundaray",
    studentimg: student2,
    role: "Software Engineer",
    companyimg: kovaion,
    quote: "“Salary jump from 3.6 to 12+ Lakhs in 10 months”",
  },
  {
    heading: "Non-IT Working Professional",
    name: "Saurav Sable",
    studentimg: saurav,
    role: "Software Engineer",
    companyimg: airtel,
    quote: "“Proper guidance helps to get into Airtel.”",
  },
  {
    heading: "Student with CS/IT Background",
    name: "Satyabrata Dhal",
    studentimg: student3,
    role: "Project Trainee",
    companyimg: wipro,
    quote: "“From losing hope to now working as an Project Trainee in Wipro”",
  },
  {
    heading: "Student with CS/IT Background",
    name: "Sarthak Singh",
    studentimg: sarthak,
    role: "Data Analyst",
    companyimg: hcl,
    quote: "“Salary jump from 3.6 to 12+ Lakhs in 10 months”",
  },
  {
    heading: "Student with CS/IT Background",
    name: "Priya Sharma",
    studentimg: priya,
    role: "Business Analyst",
    companyimg: amazon,
    quote: "“Proper guidance helps to get into Amazon.”",
  },
  {
    heading: "Student with CS/IT Background",
    name: "Chandan Chiranjeeb Sahoo",
    studentimg: student4,
    role: "Software Engineer",
    companyimg: indexnine,
    quote: "“From losing hope to now working as an Software Engineer in Indexnine”",
  },
  {
    heading: "Student with CS/IT Background",
    name: "Prakhar Jaiswal",
    studentimg: prakhar,
    role: "ML Engineer",
    companyimg: makemytrip,
    quote: "“Salary jump from 3.6 to 12+ Lakhs in 10 months”",
  },
  {
    heading: "Student with CS/IT Background",
    name: "Shubham Singh",
    studentimg: shubham,
    role: "Data Science Engineer",
    companyimg: capgemini,
    quote: "“Proper guidance helps to get into Capgemini.”",
  },
];

// export const eligibleData = [
//   {
//     image : eligible1,
//     heading:"Final Year Students",
//     subheading:"Preparing for Final Year Placements and for the long-lasting Tech Journey ahead."
//   },
//   {
//     image : eligible2,
//     heading:"Students and Graduates",
//     subheading:"Looking to start their career in tech."
//   },
//   {
//     image : eligible3,
//     heading:"Working Professionals",
//     subheading:"Looking to upskill and make that dream switch."
//   }
// ]

export const powerData = [
  {
    innerdiv1: "High Employability",
    innerdiv2:
      "Full-stack Development skills are highly sought after by employers across various industries. Mastering Full-stack Development opens doors to a wide range of job opportunities.",
  },
  {
    innerdiv1: "Adaptability",
    innerdiv2:
      "The versatility of Full-stack Development allows you to adapt to different project requirements and technologies, making you a valuable asset to any team.",
  },
  {
    innerdiv1: "Creativity",
    innerdiv2:
      "Full-stack Development encourages creativity in problem-solving and designing user-friendly interfaces, empowering you to bring innovative solutions to life.",
  },
  {
    innerdiv1: "Global Collaboration",
    innerdiv2:
      "In a digitally connected world, Full-stack Development skills enable you to collaborate with diverse teams and clients worldwide, fostering global connections.",
  },
  {
    innerdiv1: "Continuous Learning",
    innerdiv2:
      "Full-stack Development is a field that constantly evolves with new technologies and trends. Embracing Full-stack Development means committing to lifelong learning and growth.",
  },
];

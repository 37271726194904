import React from "react";
import Top from "../../Components/Top/Top";
import Service from "../../Components/Service/Service";
import AboutUs from "../../Components/AboutUs/AboutUs";
import Instructor from "../../Components/Instructor/Instructor";
import Features from "../../Components/Features/Features";

export default function About() {
  return (
  <>
  <Top data="About"/>
  <Service/>
  <AboutUs/>
  <Features/>
  <Instructor/>
  </>
)}

import "./TermsAndConditions.css";

export default function TermsAndConditions() {
  return (
    <>
      <div className="termsdiv">
        <div className="page">
          <div id="terms-and-conditions">
          <h1 className="mb-5">Terms And Conditions</h1>
            <p>
              Welcome to Tech Mentor Core. These terms and conditions govern
              your use of our website and the services provided by Tech Mentor
              Core. By accessing our website and enrolling in our courses, you
              agree to comply with these terms and conditions. Please read them
              carefully.
            </p>
            <ol>
              <li>
                <b>Registration Fees:</b>
              </li>

              <p>
                Upon registration for our courses, students are required to pay
                an initial registration fee. This fee is non-refundable except
                under specific conditions outlined below.
              </p>

              <li>
                <b>Refund Policy:</b>
              </li>

              <p>
                Refunds of the initial registration fee are only applicable
                under the following circumstances:
              </p>

              <p>
                <ul>
                  <li className="ullist">
                    {" "}
                    <b>Attendance: </b>Students must maintain a minimum
                    attendance of 80% in classes.
                  </li>
                  <li className="ullist">
                    <b>Online Tests: </b>Students must clear online tests within
                    a maximum of five attempts.
                  </li>
                  <li className="ullist">
                    <b>Mock Interviews: </b>Students must successfully complete
                    mock interview sessions conducted by Tech Mentor Core and
                    get a clearance from the interviewer..
                  </li>
                </ul>

                <p style={{ marginTop: "20px" }}>
                  Despite meeting the above criteria, students do not secure
                  placement opportunities through Tech Mentor Core. All refund
                  requests must be submitted in writing and will be processed
                  within a reasonable time frame.
                </p>
              </p>

              <p>
              Refunded amount will get credited in students account within 30 days
              </p>

              <p>
                In all other cases not specified above, no refunds will be
                issued for registration fees.
              </p>

              <li>
                <b>Placement Terms:</b>
              </li>

              <p>
                Upon securing a job placement facilitated by Tech Mentor Core,
                students are required to pay 30% of the offered salary as agreed
                upon in the offer letter.
              </p>

              <p>
                Failure to comply with the payment terms outlined above may
                result in legal action being taken against the student.
              </p>

              <li>
                <b>Payment Terms:</b>
              </li>

              <p>
                Payment for courses and services provided by Tech Mentor Core
                must be made in full according to the payment schedule outlined
                at the time of enrolment.
              </p>

              <li>
                <b> Intellectual Property:</b>
              </li>

              <p>
                All course materials, including but not limited to lectures,
                presentations, and written content, are the intellectual
                property of Tech Mentor Core and may not be reproduced,
                distributed, or shared without prior written consent.
              </p>

              <li>
                <b> Privacy Policy:</b>
              </li>

              <p>
                Tech Mentor Core is committed to protecting the privacy of our
                users. Please refer to our Privacy Policy for information on how
                we collect, use, and disclose personal information.
              </p>
              <p> <b>Usage-</b> We use personal data to provide the services you request. To the extent we use your personal data to market to you, we will provide you the ability to opt-out of such uses. We use your personal data to assist sellers and business partners in handling and fulfilling orders; enhancing customer experience; to resolve disputes; troubleshoot problems; inform you about online and offline offers, products, services, and updates; customise your experience; detect and protect us against error, fraud and other criminal activity; enforce our terms and conditions; conduct marketing research, analysis and surveys; and as otherwise described to you at the time of collection of information. You understand that your access to these products/services may be affected in the event permission is not provided to us.</p>
<p><b>Sharing-</b> We may share your personal data internally within our group entities, our other corporate entities, and affiliates to provide you access to the services and products offered by them. These entities and affiliates may market to you as a result of such sharing unless you explicitly opt-out. We may disclose personal data to third parties such as sellers, business partners, third party service providers including logistics partners, prepaid payment instrument issuers, third-party reward programs and other payment opted by you. These disclosure may be required for us to provide you access to our services and products offered to you, to comply with our legal obligations, to enforce our user agreement, to facilitate our marketing and advertising activities, to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our services. We may disclose personal and sensitive personal data to government agencies or other authorised law enforcement agencies if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal data to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms of Use or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.</p>
             <p><b>Security Precautions-</b> To protect your personal data from unauthorised access or disclosure, loss or misuse we adopt reasonable security practices and procedures. Once your information is in our possession or whenever you access your account information, we adhere to our security guidelines to protect it against unauthorised access and offer the use of a secure server. However, the transmission of information is not completely secure for reasons beyond our control. By using the Platform, the users accept the security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain inherent risks regarding use of the Platform. Users are responsible for ensuring the protection of login and password records for their account.</p>
             <p><b>Data Deletion and Retention- </b>You have an option to delete you</p>
             
              <li>
                <b> Amendments:</b>
              </li>

              <p>
                Tech Mentor Core reserves the right to modify or amend these
                terms and conditions at any time without prior notice. Any
                changes will be effective immediately upon posting on our
                website.
              </p>

              <p>
                It is your responsibility to review these terms and conditions
                periodically to ensure compliance. By continuing to use our
                website and services after any changes are made, you agree to be
                bound by the updated terms and conditions.
              </p>

              <li>
                <b>Contact Us:</b>
              </li>

              <p>
                If you have any questions or concerns regarding these terms and
                conditions, please contact us at <b>info@techmentorcore.com</b>
              </p>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}

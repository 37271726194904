import "./Time.css";

export default function Time() {
  return (
    <>
      <section className="timesection" id="brochure-page3">
        <div className="timediv">
          <h1 className="brochureheaderheading1">
            Why should you invest <br /> your time?
          </h1>
        </div>

        <div className="quotediv">
          <p>
          In the new economy, knowledge is your currency, and learning is your investment.
          </p>

          <div className="quotebtndiv">
            <button className="quotebtn">-  Brian Tracy</button>
          </div>
        </div>

        <div className="timeparadiv">
          <p>
            At Techmentorcore, we are not just an Edtech platform; we are a
            dynamic team of young visionaries committed to revolutionizing the
            teaching landscape in India. Our mission is to bridge the gap
            between skills and employability in the rapidly evolving technology
            market.We offer advanced tech skills training, meticulously
            preparing students for job interviews. 
          </p>

          <p>
            Our programs incorporate
            industry-standard projects, ensuring students gain practical
            experience and are equipped to excel in their careers from day
            one.Join us at Techmentorcore, where education meets innovation, and
            students become industry-ready professionals.
          </p>
        </div>
      </section>
    </>
  );
}

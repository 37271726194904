import React from "react";
import "./PayOnline.css";
import qr from "../../Assets/Images/TechmentorcoreQR.jpg";
// import qr from "../../Assets/Images/qrcode.png";
import Top from "../../Components/Top/Top";

export default function PayOnline() {
  const copyToClipboard = () => {
    const textToCopy = "yespay.smesm000027516@yesbankltd";
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert("Copied to clipboard!");
    }).catch(err => {
      console.error("Failed to copy text: ", err);
    });
  };

  return (
    <>
      <Top data="Pay Online"/>
      <div className="payonlinediv">
        <div className="payonlinerow">
          <div className="payonlinecolumn1">
            <h3>Scan the QR Code</h3>
            <img className="payonlineqrimg" src={qr} alt="" />
            <h5 id="paymentInfo">UPI : yespay.smesm000027516@yesbankltd</h5>{" "}
            <span id="copyButton" onClick={copyToClipboard} style={{cursor: "pointer", color: "blue"}}>
              Copy
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
